export enum ProjectHeaderWidgetComponentIds {
  Root = '#projectHeaderWidgetRoot',
  Title = '#projectTitle',
  Description = '#projectDescription',
  HeroImage = '#projectHeroImage',
  Details = '#projectDetailsWidget',
}

export const ProjectHeaderWidgetTextComponentIds = [
  ProjectHeaderWidgetComponentIds.Title,
  ProjectHeaderWidgetComponentIds.Description,
];

export enum ProjectHeaderWidgetEvents {}

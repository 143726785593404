import type { RouterPage } from '@wix/platform-editor-sdk';
import {
  Pages,
  PortfolioPagesRole,
  PortfolioRoutersPrefix,
} from './const/pagesConsts';
import {
  createAppPage,
  initPortfolioRouterPrefix,
  addPageToRouter,
} from './utils/installationUtils';
import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  IHttpClient,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { getCollectionsLength } from '../../services/dataServices';
import { portfolioAddedToStage } from '@wix/bi-logger-portfolio-users/v2';

const checkIfDefaultDataCreated = async (httpClient: IHttpClient) => {
  // while loop 5 times to check if default data is created
  for (let i = 0; i < 5; i++) {
    const collectionsLength: number = await new Promise((resolve) => {
      setTimeout(() => resolve(getCollectionsLength(httpClient)), i * 500);
    });
    if (collectionsLength > 0) {
      console.log(`default data created ${i} try`);
      return true;
    }
  }
  return false;
};

export async function handleFirstInstall(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  const { t } = flowAPI.translations;
  const biLogger = options.essentials.biLoggerFactory().logger();
  biLogger.report(portfolioAddedToStage({}));
  await checkIfDefaultDataCreated(flowAPI.httpClient);
  const isResponsive = options.origin.type === 'RESPONSIVE';
  const isStudio = options.origin.subType === 'STUDIO';

  const projectPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PROJECT_PAGE,
    isResponsive,
    isStudio,
    t,
  });
  const collectionPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.COLLECTION_PAGE,
    isResponsive,
    isStudio,
    t,
  });
  const portfolioPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PORTFOLIO_PAGE,
    isResponsive,
    isStudio,
    shouldAddMenuItem: true,
    shouldNavigateToPage: false,
    t,
  });

  const routerRef = await initPortfolioRouterPrefix(
    editorSDK,
    PortfolioRoutersPrefix.PORTFOLIO_COLLECTIONS,
  );

  const linkPagesToPortfolioRouter = async () => {
    let areAllPagesConnected = false;
    let count = 0;
    const iterationCount = 3;
    let isCollectionPageConnected = false;
    let isProjectsPageConnected = false;
    while (count < iterationCount && !areAllPagesConnected) {
      count++;
      if (!isCollectionPageConnected) {
        await addPageToRouter(
          editorSDK,
          routerRef,
          collectionPageRef,
          PortfolioPagesRole.Collection,
        );
      }
      if (!isProjectsPageConnected) {
        await addPageToRouter(
          editorSDK,
          routerRef,
          projectPageRef,
          PortfolioPagesRole.Project,
        );
      }
      const routerData = await editorSDK.routers.get('', { routerRef });
      const { pages } = routerData;
      isCollectionPageConnected = !!pages.find((page: RouterPage) => {
        return page.pageRoles.includes(Pages.COLLECTION_PAGE.pageUriSEO);
      });
      isProjectsPageConnected = !!pages.find((page: RouterPage) => {
        return page.pageRoles.includes(Pages.PROJECT_PAGE.pageUriSEO);
      });
      areAllPagesConnected = !!(
        isCollectionPageConnected && isProjectsPageConnected
      );
    }
  };
  await linkPagesToPortfolioRouter();
  await editorSDK.pages.navigateTo('', { pageRef: portfolioPageRef });
}
